/* eslint-disable */
import React from "react"
import Layout from '../../components/theme/Layout'

import ContestBlock from "../../contest/ContestBlock.js"
import ContestHeader from "../../contest/components/ContestHeader.js"
import ContestContent from "../../contest/components/ContestContent.js"
import ContestFooter from "../../contest/components/ContestFooter.js"

import { IntlProvider, addLocaleData } from 'react-intl'
import en from '../../i18n/en.json'
import fr from '../../i18n/fr.json'

import enData from 'react-intl/locale-data/en'
import frData from 'react-intl/locale-data/fr'

const messages = { en, fr }
const locale = 'fr'

addLocaleData([...enData, ...frData])

const flattenMessages = ((nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((_messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(_messages, { [prefixedKey]: value })
    } else {
      Object.assign(_messages, flattenMessages(value, prefixedKey))
    }

    return _messages
  }, {})
})

const ContestPage = ({data}) => (
  <Layout
      meta={{
        title: 'Gear Up for Spring Contest | ROYALE®'
      }}
      urls={{
        'en': `/refreshyourspace`,
        'fr': `/fr/rafraichirsondecor`
      }}
      locale={locale}
      theme_color="#A6CE39">

    {/* Date format YYYY-MM-DD */}
    {/*
      * To implement the contest, just copy & paste the <ContestBlock> component
      * on the desired page and copy the "contest" folder in the /src directory
      * the styles can be edited with props or inside individual components
      */}
        <ContestBlock
            startDate="2020-01-01"
            endDate="2020-05-01"
            locale={locale}
            >
            <ContestHeader />
            <ContestContent
                locale={locale}
                blogPosts={data.prismic.allHome_solution_featureds.edges[0].node} />
            <ContestFooter locale={locale}  />
        </ContestBlock>
        <script async src="https://www.googletagmanager.com/gtag/js?id=DC-9904654"></script>
  </Layout>
)

export const query = graphql`
{
  prismic {
    allHome_solution_featureds(lang: "fr-ca") {
      edges {
        node {
          blog_1 {
            ... on PRISMIC_Home_solution_article {
              title
              preview_image
              _meta {
                uid
              }
            }
          }
          blog_2 {
            ... on PRISMIC_Home_solution_article {
              title
              preview_image
              _meta {
                uid
              }
            }
          }
          blog_3 {
            ... on PRISMIC_Home_solution_article {
              title
              preview_image
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
}
`

export default ContestPage
